import { inject }             from 'aurelia-framework';
import { DangerAlertMessage } from 'resources/services/danger-alert-message';
import { Notifier }           from 'resources/services/notifier';

@inject(Notifier)
export class FormAlerter {

    /**
     * Constructor
     *
     * @param notifier
     */
    constructor(notifier) {
        this.notifier = notifier;
    }

    /**
     * Handle alert based on a response
     *
     * @param response
     */
    alert(response) {

        if (response.status === false) {

            // returns danger alert
            return new DangerAlertMessage(response.message, response.errors);

        }

        // throws success notice
        this.notifier.successNotice(response.message);

        // resets element
        return null;

    }

}
