import { inject }          from 'aurelia-framework';
import { UsersRepository } from 'modules/administration/users/services/repository';
import { BooleanOption }   from 'modules/administration/models/boolean-option';
import { User }            from 'modules/administration/models/user';

@inject(UsersRepository)
export class AuthenticatedUser {

    /**
     * Constructor
     *
     * @param usersRepository
     */
    constructor(usersRepository) {
        this.usersRepository = usersRepository;
    }

    /**
     * Fetches the logged user from server
     *
     * @returns {*}
     */
    fetchAuthenticatedUser() {
        return this.usersRepository.authenticatedUser().then((data) => this.user = data);
    }

    /**
     * Check whether the user is root
     *
     * @returns {boolean}
     */
    isRoot() {
        return this.user.id === User.ROOT_USER_ID;
    }

    /**
     * Check whether the user is a system reserved user
     *
     * @returns {boolean}
     */
    isSystemReserved() {
        return this.user.system_reserved === BooleanOption.YES;
    }

    /**
     * Check whether the user is a client
     *
     * @returns {boolean}
     */
    isClient() {
        return this.user.is_client;
    }

    /**
     * Returns current tenant
     *
     * @returns {*}
     */
    currentTenant() {
        return this.user.current_tenant;
    }

    /**
     * Returns subscribed diploma classifications
     *
     * @returns {boolean}
     */
    subscribedDiplomaClassifications() {
        return !this.hasCurrentTenant() ? [] : this.user.current_tenant.subscribed_diploma_classification_ids;
    }

    /**
     * Checks whether the authenticated user has a given diploma classification subscribed
     *
     * @param {int|array} diplomaClassification
     *
     * @returns {boolean}
     */
    hasDiplomaClassificationSubscribed(diplomaClassification) {
        let diplomaClassifications = this.getArrayFrom(diplomaClassification);

        return !this.hasCurrentTenant() || this.subscribedDiplomaClassifications().filter(value => -1 !== diplomaClassifications.indexOf(value)).length > 0;
    }

    /**
     * Check whether the session has a current tenant
     *
     * @returns {boolean}
     */
    hasCurrentTenant() {
        return typeof this.currentTenant() !== 'undefined' && this.currentTenant() !== null;
    }

    /**
     * Check if the user has a role or roles.
     *
     * @param role
     * @param all
     *
     * @returns {boolean}
     */
    is(role, all = false) {
        let roles = this.getArrayFrom(role);

        return all ? this.isAll(roles) : this.isOne(roles);
    }

    /**
     * Check if the user has at least one role.
     *
     * @param roles
     *
     * @returns {boolean}
     */
    isOne(roles) {
        let length = roles.length;

        for (let i = 0; i < length; i++) {
            if (this.hasRole(roles[i])) {
                return true;
            }
        }

        return false;
    }

    /**
     * Check if the user has all roles.
     *
     * @param roles
     *
     * @returns {boolean}
     */
    isAll(roles) {
        let length = roles.length;

        for (let i = 0; i < length; i++) {
            if (!this.hasRole(roles[i])) {
                return false;
            }
        }

        return true;
    }

    /**
     * Check if user has a given role
     *
     * @param role
     *
     * @returns {boolean}
     */
    hasRole(role) {
        let allRoles = this.user.roles;
        let length   = allRoles.length;

        for (let i = 0; i < length; i++) {
            if (allRoles[i].slug === role) {
                return true;
            }
        }

        return false;
    }

    /**
     * Check if the user has a permission or permissions.
     *
     * @param permission
     * @param all
     *
     * @returns {boolean}
     */
    can(permission, all = false) {
        let permissions = this.getArrayFrom(permission);

        return all ? this.canAll(permissions) : this.canOne(permissions);
    }

    /**
     * Check if the user has at least one permission.
     *
     * @param permissions
     *
     * @returns {boolean}
     */
    canOne(permissions) {
        let length = permissions.length;

        for (let i = 0; i < length; i++) {
            if (this.hasPermission(permissions[i])) {
                return true;
            }
        }

        return length === 0;
    }

    /**
     * Check if the user has all permissions.
     *
     * @param permissions
     *
     * @returns {boolean}
     */
    canAll(permissions) {
        let length = permissions.length;

        for (let i = 0; i < length; i++) {
            if (!this.hasPermission(permissions[i])) {
                return false;
            }
        }

        return length === 0;
    }

    /**
     * Check if user has a given permission
     *
     * @param permission
     *
     * @returns {boolean}
     */
    hasPermission(permission) {
        let allPermissions = this.user.all_permissions;
        let length         = allPermissions.length;

        for (let i = 0; i < length; i++) {
            if (allPermissions[i].slug === permission) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns an argument as an array
     *
     * @param argument
     *
     * @returns {*[]}
     */
    getArrayFrom(argument) {
        return argument.constructor === Array ? argument : [argument];
    }

}
