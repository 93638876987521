// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function (predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return kValue.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                // e. Increase k by 1.
                k++;
            }

            // 7. Return undefined.
            return undefined;
        },
    });
}

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
        value: function (searchElement, fromIndex) {

            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            // 1. Let O be ? ToObject(this value).
            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If len is 0, return false.
            if (len === 0) {
                return false;
            }

            // 4. Let n be ? ToInteger(fromIndex).
            //    (If fromIndex is undefined, this step produces the value 0.)
            var n = fromIndex | 0;

            // 5. If n ≥ 0, then
            //  a. Let k be n.
            // 6. Else n < 0,
            //  a. Let k be len + n.
            //  b. If k < 0, let k be 0.
            var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

            function sameValueZero(x, y) {
                return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
            }

            // 7. Repeat, while k < len
            while (k < len) {
                // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                // b. If SameValueZero(searchElement, elementK) is true, return true.
                if (sameValueZero(o[k], searchElement)) {
                    return true;
                }
                // c. Increase k by 1.
                k++;
            }

            // 8. Return false
            return false;
        },
    });
}

if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function (obj, fromIndex) {

        if (fromIndex == null) {
            fromIndex = 0;
        } else if (fromIndex < 0) {
            fromIndex = Math.max(0, this.length + fromIndex);
        }

        for (var i = fromIndex, j = this.length; i < j; i++) {
            if (this[i] === obj) {
                return i;
            }
        }

        return -1;
    };
}

String.prototype.escapeHtml = function () {
    return this.replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
};

String.prototype.replaceAll = function (find, replace) {
    var str = this;

    return str.replace(new RegExp(find, 'g'), replace);
};

window.isTrue = function (b) {
    return (/^(true|yes|1|y|on)$/i).test(b);
};

window.isFalse = function (b) {
    return (/^(false|no|0|n|off)$/i).test(b);
};

window.isEmpty = function (a) {
    if (typeof a === 'number') {
        return false;
    }

    if (typeof a === 'boolean') {
        return false;
    }

    return a === undefined || a === null || a === '' || a.length === 0 || Object.keys(a).length === 0;
};

window.isBoolean = function (b) {
    return (typeof b === 'boolean');
};

window.isString = function (a) {
    return (typeof a === 'string');
};

window.isNumber = function (a) {
    return (typeof a === 'number' && Number.isInteger(a));
};

window.isDecimal = function (a) {
    return (typeof a === 'number');
};

window.isNumeric = function (obj) {
    var realStringObj = obj && obj.toString();

    return typeof obj !== 'undefined' && obj !== null && !obj.isArray && (realStringObj - parseFloat(realStringObj) + 1) >= 0;
};

window.isFunction = function (a) {
    return (typeof a === 'function');
};

window.isArray = function (a) {
    return Array.isArray ? Array.isArray(a) : Object.prototype.toString.call(a) === '[object Array]';
};

window.isObject = function (obj) {
    return (!!obj) && (obj.constructor === Object);
};

window.inArray = function (array, item) {
    return (array.indexOf(item) !== -1);
};

// TODO: replace single letters with something more intuitive
window.generateUuid = function () {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (a) {
        return (a ^ Math.random() * 16 >> a / 4).toString(16);
    });
};
