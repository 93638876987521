import { inject }            from 'aurelia-framework';
import { AuthenticatedUser } from 'resources/services/authenticated-user';
import { Notifier }          from 'resources/services/notifier';
import { I18N }              from 'aurelia-i18n';

@inject(AuthenticatedUser, Notifier, I18N)
export class MenuAccessStep {

    /**
     * Constructor
     *
     * @param authenticatedUser
     * @param notifier
     * @param i18n
     */
    constructor(authenticatedUser, notifier, i18n) {
        this.authenticatedUser = authenticatedUser;
        this.notifier          = notifier;
        this.i18n              = i18n;
    }

    /**
     * Runs step
     *
     * @param navigationInstruction
     * @param next
     *
     * @returns {*}
     */
    run(navigationInstruction, next) {
        let navigationInstructions = navigationInstruction.getAllInstructions();

        if (navigationInstructions.length > 0) {
            let menu = navigationInstructions[0].config.settings.module;

            if (typeof menu !== 'undefined' && !this.authenticatedUser.can(menu + '.menu.access')) {
                let notice = this.i18n.tr('text.error-message.forbidden');

                this.notifier.dangerNotice(notice);

                return next.cancel();
            }
        }

        return next();
    }
}
