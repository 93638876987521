import { inject }            from 'aurelia-framework';
import { Storage }           from 'resources/services/storage';
import { AureliaAuthConfig } from 'resources/configs/aurelia-auth-config';

@inject(AureliaAuthConfig)
export class LocalStorage extends Storage {

    /**
     * Constructor
     */
    constructor(authConfig) {
        super('localStorage');

        this.authConfig = authConfig;
    }

    /**
     * Returns authentication token
     *
     * @returns {*}
     */
    authToken() {
        let tokenName = this.authConfig.tokenPrefix ? `${this.authConfig.tokenPrefix}_${this.authConfig.tokenName}` : this.authConfig.tokenName;

        return this.get(tokenName);
    }

}
