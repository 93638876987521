import { Storage } from 'resources/services/storage';

export class SessionStorage extends Storage {

    /**
     * Constructor
     */
    constructor() {
        super('sessionStorage');
    }

}
