import { inject }            from 'aurelia-framework';
import { AuthenticatedUser } from 'resources/services/authenticated-user';
import { CustomHttpClient }  from 'resources/services/custom-http-client';
import { SessionStorage }    from 'resources/services/session-storage';
import { LocalStorage }      from 'resources/services/local-storage';
import { EventAggregator }   from 'aurelia-event-aggregator';
import { FormAlerter }       from 'resources/services/form-alerter';
import { Notifier }          from 'resources/services/notifier';
import { GlobalConfig }      from 'resources/configs/global-config';
import { BindingEngine }     from 'aurelia-binding';
import { Router }            from 'aurelia-router';
import { I18N }              from 'aurelia-i18n';
import { DialogService }     from 'aurelia-dialog';

@inject(AuthenticatedUser, CustomHttpClient, SessionStorage, LocalStorage, EventAggregator, FormAlerter, Notifier, GlobalConfig, BindingEngine, Router, I18N, DialogService)
export class AppContainer {

    currentFilePermissions = null;

    /**
     * Constructor
     *
     * @param authenticatedUser
     * @param httpClient
     * @param sessionStorage
     * @param localStorage
     * @param eventAggregator
     * @param formAlerter
     * @param notifier
     * @param globalConfig
     * @param bindingEngine
     * @param router
     * @param i18n
     * @param dialogService
     */
    constructor(authenticatedUser, httpClient, sessionStorage, localStorage, eventAggregator, formAlerter, notifier, globalConfig, bindingEngine, router, i18n, dialogService) {
        this.authenticatedUser = authenticatedUser;
        this.httpClient        = httpClient;
        this.sessionStorage    = sessionStorage;
        this.localStorage      = localStorage;
        this.eventAggregator   = eventAggregator;
        this.formAlerter       = formAlerter;
        this.notifier          = notifier;
        this.globalConfig      = globalConfig;
        this.bindingEngine     = bindingEngine;
        this.router            = router;
        this.i18n              = i18n;
        this.dialogService     = dialogService;
    }

}
