import { block } from 'block-ui';

export class BlockUiService {

    /**
     * Blocks UI
     *
     * @param target
     */
    block(target = $('body')) {
        if ($(target).find('.blockUI').length === 0) {
            $(target).block({
                message:
                    '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">\n' +
                    '    <circle class="path" fill="none" stroke-width="4" stroke-linecap="round" cx="33" cy="33" r="30"></circle>\n' +
                    '</svg>',
                overlayCSS: {
                    zIndex:          '9999', // temporary fix to the modal submit problem (JFM)
                    backgroundColor: '#fff',
                    opacity:         0.8,
                    cursor:          'wait',
                    'box-shadow':    '0 0 0 1px #ddd',
                },
                css:        {
                    border:          0,
                    padding:         0,
                    backgroundColor: 'none',
                    zIndex:          '9999', // temporary fix to the modal submit problem (JFM)
                },
            });
        }
    }

    /**
     * Unblocks UI
     *
     * @param target
     */
    unblock(target = $('body')) {
        if ($(target).find('.blockUI').length > 0) {
            $(target).unblock();
        }
    }
}
