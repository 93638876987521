export class Storage {

    type    = 'localStorage';
    storage = undefined;

    /**
     * Constructor
     *
     * @param type
     */
    constructor(type) {
        this.type    = type || this.type;
        this.storage = this.getStorage();
    }

    /**
     * Returns an item by its key
     *
     * @param key
     */
    get(key) {
        return this.storage.getItem(key);
    }

    /**
     * Sets an item by its key
     *
     * @param key
     * @param value
     */
    set(key, value) {
        return this.storage.setItem(key, value);
    }

    /**
     * Removes an item by its key
     *
     * @param key
     */
    remove(key) {
        return this.storage.removeItem(key);
    }

    /**
     * Returns storage
     *
     * @returns {Storage}
     */
    getStorage() {
        if (this.type === 'localStorage') {
            if ('localStorage' in window && window.localStorage !== null) {
                return localStorage;
            }

            throw new Error(`Local Storage is disabled or unavailable.`);
        }

        if (this.type === 'sessionStorage') {
            if ('sessionStorage' in window && window.sessionStorage !== null) {
                return sessionStorage;
            }

            throw new Error(`Session Storage is disabled or unavailable.`);
        }

        throw new Error(`Invalid storage type specified: ${this.type}`);
    }

}