import { inject } from 'aurelia-framework';
import moment     from 'moment';

@inject(moment)
export class Downloader {

    /**
     * Constructor
     *
     * @param moment
     */
    constructor(moment) {
        this.moment = moment;
    }

    /**
     * Opens a file in a new windows
     *
     * @param content
     * @param filename
     * @param extension
     * @param includeTimestamp
     */
    open(content, filename, extension, includeTimestamp = true) {
        if (window.navigator.msSaveOrOpenBlob) {

            window.navigator.msSaveOrOpenBlob(
                content,
                this.generateFinalFilename(
                    filename, extension, includeTimestamp,
                ),
            );

        } else {

            let objectUrl = URL.createObjectURL(content);

            // opens object in a new window
            window.open(objectUrl);

        }
    }

    /**
     * Downloads a file
     *
     * @param content
     * @param filename
     * @param extension
     * @param includeTimestamp
     */
    download(content, filename, extension, includeTimestamp = false) {
        if (window.navigator.msSaveOrOpenBlob) {

            window.navigator.msSaveOrOpenBlob(
                content,
                this.generateFinalFilename(
                    filename, extension, includeTimestamp,
                ),
            );

        } else {

            let objectUrl = URL.createObjectURL(content);
            let anchor    = document.createElement('a');

            anchor.href     = objectUrl;
            anchor.download = this.generateFinalFilename(filename, extension, includeTimestamp);

            // adds anchor to dom
            document.body.appendChild(anchor);

            // simulates user click
            anchor.click();

            // removes anchor from dom
            document.body.removeChild(anchor);

        }
    }

    /**
     * Generates a final filename
     *
     * @param filename
     * @param extension
     * @param includeTimestamp
     *
     * @returns {string}
     */
    generateFinalFilename(filename, extension, includeTimestamp = true) {
        let timestamp = includeTimestamp ? `_${this.moment.format('YYYYMMDDHHmmss')}` : ``;

        return `${filename}${timestamp}.${extension}`;
    }

}
