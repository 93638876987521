import { Aurelia, inject, observable } from 'aurelia-framework';
import { HttpClient, json }            from 'aurelia-fetch-client';
import { EventAggregator }             from 'aurelia-event-aggregator';
import { AuthService }                 from 'aurelia-authentication';
import { I18N }                        from 'aurelia-i18n';
import { Notifier }                    from 'resources/services/notifier';
import { BlockUiService }              from 'resources/services/block-ui-service';
import { GlobalConfig }                from 'resources/configs/global-config';
import { AureliaAuthConfig }           from 'resources/configs/aurelia-auth-config';

@inject(Aurelia, EventAggregator, AuthService, Notifier, I18N, BlockUiService)
export class CustomHttpClient extends HttpClient {

    @observable activeRequestCount;

    eventListeners = [];

    /**
     * Constructor
     *
     * @param aurelia
     * @param eventAggregator
     * @param authService
     * @param notifier
     * @param i18n
     * @param blockUiService
     */
    constructor(aurelia, eventAggregator, authService, notifier, i18n, blockUiService) {
        super();

        this.aurelia         = aurelia;
        this.eventAggregator = eventAggregator;
        this.authService     = authService;
        this.notifier        = notifier;
        this.i18n            = i18n;
        this.blockUiService  = blockUiService;

        this.subscribeEventListeners();
        this.doConfigure();
    }

    /**
     * Handles active request count changed
     */
    activeRequestCountChanged() {
        if (this.blockUiService) {
            this.activeRequestCount ? this.blockUiService.block() : this.blockUiService.unblock();
        }
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        // subscribes `locale-changed` event
        this.eventListeners.push(this.eventAggregator.subscribe('locale-changed', () => this.doConfigure()));
    }

    /**
     * Does service configuration
     */
    doConfigure() {
        let self = this;

        this.configure(config => {
            config
                .withBaseUrl(GlobalConfig.backendBaseApiUrl)
                .withDefaults({
                    credentials: 'same-origin',
                    headers:     {
                        'Accept':             'application/json',
                        'X-Requested-With':   'XMLHttpRequest',
                        'Application-Locale': self.i18n.getLocale(),
                    },
                })
                .withInterceptor({
                    response(response) {
                        if (response.status === 400 || response.status === 401) {
                            self.authService.logout(AureliaAuthConfig.logoutRedirect).then(() => self.aurelia.setRoot('login'));
                        } else if (response.status === 403) {
                            self.notifier.dangerNotice(self.i18n.tr('text.error-message.forbidden'));
                        }

                        return response;
                    },
                    responseError(error) {
                        self.notifier.dangerNotice(self.i18n.tr('text.error-message.action-failed'));

                        throw error;
                    },
                });
        });
    }

    /**
     * Performs a GET request
     *
     * @param route
     *
     * @returns Promise<Response>
     */
    get(route) {
        return this.rawGet(route).then((response) => response.json());
    }

    /**
     * Performs a "raw" GET request
     *
     * @param route
     *
     * @returns Promise<Response>
     */
    rawGet(route) {
        return this.fetch(route);
    }

    /**
     * Performs a POST request
     *
     * @param route
     * @param body
     *
     * @returns Promise<Response>
     */
    post(route, body) {
        return this.rawPost(route, body).then((response) => response.json());
    }

    /**
     * Performs a "raw" POST request
     *
     * @param route
     * @param body
     *
     * @returns Promise<Response>
     */
    rawPost(route, body) {
        return this.fetch(route, {
            method: 'POST',
            body:   json(body),
        });
    }

    /**
     * Performs a PATCH request
     *
     * @param route
     * @param body
     *
     * @returns Promise<Response>
     */
    patch(route, body) {
        return this.rawPatch(route, body).then((response) => response.json());
    }

    /**
     * Performs a "raw" PATCH request
     *
     * @param route
     * @param body
     *
     * @returns Promise<Response>
     */
    rawPatch(route, body) {
        return this.fetch(route, {
            method: 'PATCH',
            body:   json(body),
        });
    }

    /**
     * Performs a PUT request
     *
     * @param route
     * @param body
     *
     * @returns Promise<Response>
     */
    put(route, body) {
        return this.rawPut(route, body).then((response) => response.json());
    }

    /**
     * Performs a "raw" PUT request
     *
     * @param route
     * @param body
     *
     * @returns Promise<Response>
     */
    rawPut(route, body) {
        return this.fetch(route, {
            method: 'PUT',
            body:   json(body),
        });
    }

    /**
     * Performs a DELETE request
     *
     * @param route
     * @param body
     *
     * @returns Promise<Response>
     */
    delete(route, body) {
        return this.rawDelete(route, body).then((response) => response.json());
    }

    /**
     * Performs a "raw" DELETE request
     *
     * @param route
     * @param body
     *
     * @returns Promise<Response>
     */
    rawDelete(route, body) {
        return this.fetch(route, {
            method: 'DELETE',
            body:   json(body),
        });
    }

    /**
     * Generates a route from a relative route
     *
     * @param relativeRoute
     *
     * @returns {string}
     */
    generateRoute(relativeRoute) {
        return GlobalConfig.backendBaseApiUrl + relativeRoute;
    }
}
