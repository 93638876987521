import { inject }      from 'aurelia-framework';
import { AuthService } from 'aurelia-authentication';
import Echo            from 'laravel-echo';
import io              from 'socket.io-client';
import environment     from 'environment';

@inject(AuthService)
export class LaravelEchoService {

    instantiated = false;

    /**
     * Constructor
     *
     * @param authService
     */
    constructor(authService) {
        this.authService = authService;
    }

    /**
     * Instantiates laravel echo
     */
    instantiate() {
        if (environment.webSockets.supported) {
            window.Echo = new Echo({
                client:      io,
                broadcaster: environment.webSockets.broadcaster.name,
                host:        environment.webSockets.broadcaster.endpoint,
                key:         environment.webSockets.broadcaster.apiKey,
                namespace:   environment.webSockets.broadcaster.namespace,
                auth:        {
                    headers: {
                        'Authorization': `Bearer ${this.authService.getAccessToken()}`,
                    },
                },
            });

            this.instantiated = true;
        }
    }
}
